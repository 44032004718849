<template>
<div class="mod-config" style="padding: 0px;">

	<hbi-design /> 

</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>
